<template>
  <div
    fluid
    :style="{
      backgroundImage:
        'url(' + require('@/assets/images/' + background_desktop) + ')',
      height: '100vh',
      width: '100vw',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
      backgroundSize: 'cover',
    }"
    class="background-container"
  >
    <!-- <div style="margin-top: 40px; margin-left: 150px">
      <v-btn
        class="text-none"
        v-if="goBack"
        @click="goBackAction()"
        outlined
        :style="{
          'font-family': 'Georama',
          'font-size': '16px',
          'font-weight': '400',
          'line-height': '20px',
          'letter-spacing': '0em',
          padding: '5px 10px',
        }"
      >
        <v-icon left>mdi-arrow-left</v-icon>
        Retour
      </v-btn>
    </div> -->
    <div :rail="rail" permanent class="accueil-drawer">
      <Accueil></Accueil>
    </div>
    <div
      class="
        container-small
        d-flex
        flex-column
        justify-center
        align-center
        photo-capture-container
      "
    >
      <div class="photo-status">
        <h2
          class="text-center mb-5"
          :style="{
            color: WEBAPPPRO_TEXT_COLOR_4,
            fontFamily: WEBAPPPRO_FONT_FAMILY_2,
            fontSize: WEBAPPPRO_FONT_SIZE_2,
            fontWeight: 700,
            marginTop: '10px',
          }"
        >
          Prise de photo en attente
        </h2>
        <p
          class="text-center"
          :style="{
            // color: WEBAPPPRO_TEXT_COLOR_2,
            fontFamily: WEBAPPPRO_FONT_FAMILY_3,
            fontSize: WEBAPPPRO_FONT_SIZE_3,
            fontWeight: 400,
            marginTop: '10px',
          }"
        >
          Retrouvez ici la liste des candidats ayant bien reçu le lien <br />
          mais n’ayant pas encore effectué sa photo.
        </p>
      </div>
      <v-card
        class="pa-6"
        :style="{ width: '735px', borderRadius: WEBAPPPRO_CARD_BORDER_ROUND }"
      >
        <v-data-table
          :headers="headers"
          :items="
            items.map(item => ({
              ...item,
              createdAt: formatCreatedAt(item.createdAt),
            }))
          "
          :items-per-page="10"
        >
          <template v-slot:[`item.createdAt`]="{ item }">
            <td class="bold-text" :style="{ fontSize: WEBAPPPRO_FONT_SIZE_3 }">
              {{ item.createdAt }}
            </td>
          </template>
          <template v-slot:[`item.email`]="{ item }">
            <td class="bold-text" :style="{ fontSize: WEBAPPPRO_FONT_SIZE_3 }">
              {{ item.email }}
            </td>
          </template>
          <template v-slot:[`item.lastName`]="{ item }">
            <td class="bold-text" :style="{ fontSize: WEBAPPPRO_FONT_SIZE_3 }">
              {{ item.lastName }}
            </td>
          </template>
          <template v-slot:[`item.firstName`]="{ item }">
            <td class="bold-text" :style="{ fontSize: WEBAPPPRO_FONT_SIZE_3 }">
              {{ item.firstName }}
            </td>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <td
              class="d-flex bold-text"
              :style="{ fontSize: WEBAPPPRO_FONT_SIZE_3 }"
            >
              <v-btn class="mx-2" @click="viewQrCode(item)"
                >Voir le qrCode</v-btn
              >
              <v-btn class="mx-2" @click="resendQrCode(item.uid)"
                >Renvoyer le qrCode à l'élève</v-btn
              >
              <v-btn class="mx-2" @click="deleteItem(item)">Supprimer</v-btn>
            </td>
          </template>
        </v-data-table>
      </v-card>
    </div>
    <v-dialog v-model="dialog" max-width="300px">
      <div
        class="mx-auto mt-4 mb-4"
        :style="{
          width: '250px',
          height: '250px',
          border: '2px solid ' + WEBAPPPRO_QRCODE_BORDER_COLOR,
          borderRadius: WEBAPPPRO_CARD_BORDER_ROUND,
          boxShadow: WEBAPPPRO_QRCODE_SHADOW,
          padding: '5px',
          backgroundColor: 'white',
        }"
      >
        <v-img :src="qrCodeSrc"></v-img>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { localStorageService } from '@/services/localStorage.service'
import { requestService } from '@/services/request.service'
import Accueil from './Accueil.vue'
import { refreshToken } from './RefreshToken.vue'
export default {
  name: 'WaitingOrders',
  components: {
    Accueil,
  },
  data: () => ({
    styleTitle: localStorageService.getStyle(location.hostname, 'titleStyle'),
    buttonTextValidColor: localStorageService.getStyle(
      location.hostname,
      'buttonTextValidColor',
    ),
    WEBAPPPRO_ICON_COLOR_INFO: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_ICON_COLOR_INFO',
    ),
    WEBAPPPRO_COLOR_1: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_COLOR_1',
    ),
    WEBAPPPRO_COLOR_2: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_COLOR_2',
    ),
    WEBAPPPRO_COLOR_3: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_COLOR_3',
    ),
    WEBAPPPRO_TEXT_COLOR_1: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_TEXT_COLOR_1',
    ),
    WEBAPPPRO_TEXT_COLOR_2: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_TEXT_COLOR_2',
    ),
    WEBAPPPRO_TEXT_COLOR_3: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_TEXT_COLOR_3',
    ),
    WEBAPPPRO_BUTTON_COLOR_1: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_BUTTON_COLOR_1',
    ),
    WEBAPPPRO_BUTTON_TEXT_COLOR_1: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_BUTTON_TEXT_COLOR_1',
    ),
    icon: localStorageService.getStyle(location.hostname, 'icon'),
    iconWidth: localStorageService.getStyle(location.hostname, 'iconWidth'),
    WEBAPPPRO_FONT_FAMILY_1: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_FONT_FAMILY_1',
    ),
    WEBAPPPRO_FONT_FAMILY_2: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_FONT_FAMILY_2',
    ),
    WEBAPPPRO_FONT_SIZE_1: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_FONT_SIZE_1',
    ),
    WEBAPPPRO_FONT_SIZE_2: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_FONT_SIZE_2',
    ),
    WEBAPPPRO_FONT_SIZE_3: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_FONT_SIZE_3',
    ),
    WEBAPPPRO_FONT_FAMILY_3: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_FONT_FAMILY_3',
    ),
    WEBAPPPRO_BUTTON_FONT_SIZE: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_BUTTON_FONT_SIZE',
    ),
    WEBAPPPRO_CARD_BORDER_ROUND: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_CARD_BORDER_ROUND',
    ),
    WEBAPPPRO_BUTTON_BORDER_ROUND: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_BUTTON_BORDER_ROUND',
    ),
    WEBAPPPRO_BUTTON_SHADOW_1: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_BUTTON_SHADOW_1',
    ),
    WEBAPPPRO_BUTTON_SHADOW_2: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_BUTTON_SHADOW_2',
    ),
    background_mobile: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_BACKGROUND_MOBILE',
    ),
    WEBAPPPRO_CARD_SHADOW_1: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_CARD_SHADOW_1',
    ),
    WEBAPPPRO_CARD_SHADOW_2: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_CARD_SHADOW_2',
    ),
    WEBAPPPRO_CARD_SHADOW_3: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_CARD_SHADOW_3',
    ),
    validatedImages: localStorageService.getValue('validatedImages'),
    addOffer: localStorageService.getValue('addOfferId'),
    WEBAPPPRO_LOGO_1: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_LOGO_1',
    ),
    WEBAPPPRO_LOGO_2: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_LOGO_2',
    ),
    WEBAPPPRO_CARTE: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_CARTE',
    ),
    WEBAPPPRO_CARD_COLOR: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_CARD_COLOR',
    ),
    WEBAPPPRO_COLOR_1_FILL_ICON: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_COLOR_1_FILL_ICON',
    ),
    WEBAPPPRO_TEXT_COLOR_ETAPES: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_TEXT_COLOR_ETAPES',
    ),
    background_desktop: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_BACKGROUND_DESKTOP',
    ),
    WEBAPPPRO_TEXT_COLOR_4: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_TEXT_COLOR_4',
    ),
    items: [],
    goBack: true,
    headers: [
      { text: 'Prénom', value: 'firstName' },
      { text: 'Nom', value: 'lastName' },
      { text: 'Email', value: 'email' },
      { text: 'Date de création', value: 'createdAt' },
      { text: '', value: 'actions' },
    ],
    qrCodeSrc: '',
    dialog: false,
  }),
  methods: {
    viewQrCode(item) {
      requestService
        .post(
          `/service/authentication_qrcode_request`,
          {
            user_email: item.email, // Utilise l'email de l'item
            partner_email: localStorageService.getValue('partnerEmail'),
            partner_admin_uuid: this.$route.params.partner_uid,
          },
          // {
          //   headers: {
          //     Authorization: `Bearer ${this.$route.params.partner_uid}`,
          //     'Content-Type': 'application/json',
          //   }
          // }
        )
        .then(data => {
          this.$store.dispatch('alert/success', 'Le qrCode a été bien affiché ')
          this.qrCodeSrc = 'data:image/jpeg;base64,' + data.data.qrCode
          this.dialog = true // Ouvre le dialog
        })
        .catch(() => {
          this.$store.dispatch(
            'alert/error',
            "Impossible d'afficher le qrCode.",
          )
        })
    },
    resendQrCode(uid) {
      try {
        requestService.get(`service/relaunch/${uid}`).then(response => {
          if (response.data.success) {
            this.$store.dispatch(
              'alert/success',
              'Le QR Code a été renvoyé avec succès.',
            )
          } else {
            this.$store.dispatch(
              'alert/error',
              "Le QR Code n'a pas pu être renvoyé.",
            )
          }
        })
      } catch (error) {
        this.$store.dispatch(
          'alert/error',
          "Une erreur s'est produite lors de l'envoi du QR Code.",
        )
      }
    },
    deleteItem(item) {
      if (confirm(`Êtes-vous sûr de vouloir supprimer cet élément ?`)) {
        const index = this.items.findIndex(i => i.id === item.id) // Comparer avec l'email par exemple
        if (index > -1) {
          this.items.splice(index, 1) // Supprime l'élément trouvé
          this.$store.dispatch(
            'alert/success',
            "L'élément a été supprimé avec succès.",
          )
        } else {
          this.$store.dispatch(
            'alert/error',
            "L'élément n'a pas pu être trouvé dans la liste.",
          )
        }
      }
    },
    async handleRefreshToken() {
      try {
        await refreshToken()
      } catch (error) {
        console.error('Erreur lors du rafraîchissement du token:', error)
        // Gérez les erreurs spécifiques ici si nécessaire
      }
    },
    goBackAction() {
      this.$router.back()
      document.activeElement.blur()
    },
    formatCreatedAt(createdAt) {
      const date = new Date(createdAt)
      // Utilisez les méthodes de Date pour formater la date comme vous le souhaitez
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      }
      return date.toLocaleDateString('fr-FR', options)
    },
  },
  mounted() {
    // Appeler la méthode handleRefreshToken lors du montage du composant
    this.handleRefreshToken()
    var uuid = this.$route.params.partner_uid
    if (uuid !== null) {
      requestService
        .get('/partner/orders/' + uuid + '/waiting')
        .then(response => {
          if (response.data) {
            this.items = response.data
          }
        })
    } else {
      this.$router.push({
        name: 'NotAuthorizedPartner',
        params: {
          partner_uid: this.$route.params.partner_uid,
        },
      })
    }
    // if (uuid !== undefined && uuid !== null) {
    //   requestService
    //     .get(
    //       '/service/authentication_qrcode_check',
    //       {},
    //       {},
    //       { shortlive_api_key: this.$route.params.id, language: 'fr' },
    //     )
    //     .then(data => {
    //       localStorageService.setObject('qrCodeBase64', data.data.qrCodeBase64)
    //     })
    //     .catch(() => {
    //       console.log('hello')
    //       //this.$router.push({ name: 'ConfirmationEmail' })
    //     })
    //   console.log(localStorageService.getValue(this.$route.params.id))
    // }
  },
}
</script>

<style scoped>
.photo-capture-container {
  display: flex;
  flex-direction: column;
  margin-top: 5%;
  justify-content: center;
  align-items: center;
}

.photo-icon {
  margin: auto;
}

.photo-status {
  text-align: center;
  margin-bottom: 40px;
}

.text-status {
  text-align: center;
  margin-bottom: 5%;
}

.photo-details {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.photo-timestamp {
  margin-bottom: 5px;
}
.accueil-drawer {
  width: 300px; /* Ajustez la largeur du drawer selon vos besoins */
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  overflow-y: auto;
}
.background-container {
  display: flex;
  flex-direction: column;
}
.bold-text {
  font-weight: bold;
}
</style>
